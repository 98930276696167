import React, { useEffect,useState} from "react";
import { Container, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import Header2 from "../components/Header2/header2";
import Footer from "../components/Footer/footer";
import ImageTransform from "../components/common/ggfx-client/module/components/image-transform";
import ContactSection from '../components/SectionContact/section-contact'
import Banner from '../components/NewDevelopment/banner'
import Description from '../components/NewDevelopment/description'
import Gallery from '../components/NewDevelopment/gallery'
import About from '../components/NewDevelopment/about'
import GetArea from '../components/NewDevelopment/get-area-property'
import Register from '../components/NewDevelopment/register'
import Officemap from "../components/office-location/office-location";
import NewModal from "../components/NewDevelopment/NewDevelopmentModal/NewModal";
import SEO from "../components/Seo/seo";


export default ({ data }) => {
  const Page = data.glstrapi.newDevelopments[0];
  const GlobalConfig = data.strapiGlobalConfig;
  const [show, setShow] = useState(false);
   //console.log('Page--', Page)
  const metadesc = "Look into " + Page.Title + " here, subscribe with Orlando Reid and stay updated.";
  

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShow(true);
      if (Page?.New_Development_Modal?.ShowModal) {
        document.body.classList.add('new-modal-loading');
      }
    }, 6000);
    return () => clearTimeout(timeoutId);
  }, []);
  
  const handleCloseModal = () => {
    setShow(false);
    if (Page?.New_Development_Modal?.ShowModal) {
      document.body.classList.remove('new-modal-loading');
    }
  };
  
let processedImages = JSON.stringify({});
  if (Page?.Imagetransforms?.Modal_Bg_Transforms) {
      processedImages = Page?.Imagetransforms?.Modal_Bg_Transforms;
  }
  return (
    <>
      <SEO title={Page.Title} description={metadesc} />
      <div className="wrapper new-development-page">
        <Header2 calendly={Page.Calendly_Link} phonecall={Page.Call_Number} Enquire_Now={true} />
        <Banner {...Page} Enquire_Now={true} />
        <Description {...Page} />
        <Gallery {...Page} />
        <About {...Page} />
        <div className="full-map-area" id="location">
          <Officemap data={Page} Latitude={Page.Latitude} Longitude={Page.Longitude} />
        </div>
        {
          Page.AreaGuide &&
          <div id="local-life">
            <GetArea areaguide={Page.AreaGuide} />
          </div>
        }
        <Register {...Page} />
        <Footer footerDetails={GlobalConfig}  popularSearch="Popular_Search_Static_Common"/> 

        {Page?.New_Development_Modal?.ShowModal && 
        <Modal
				show={show}
				centered={true}
        onHide={handleCloseModal}
        backdrop="static"
				dialogClassName="modal-90w modal-form"
				aria-labelledby="new-development-modal"
        id="new-development-modal-container"
			>
      <div className="modal-content-bg">
        <picture>
            <ImageTransform imagesources={Page?.New_Development_Modal?.Modal_Bg?.url} renderer="bgImg" imagename="articles.Banner_Image.popupbanner" attr={{ alt: Page?.Title+ ' - Orlando Reid' }} imagetransformresult={processedImages} id={Page?.id} className="img-fluid modal-bg-img" />
        </picture>
        <div className="overlay-bg"></div>
      </div>
      <div className="modal-content-data">
				<Modal.Header closeButton>
					<Modal.Title id="new-development-modal-styling-title">
					{Page?.New_Development_Modal?.Modal_Title}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
          <NewModal data={Page?.New_Development_Modal}/>
				</Modal.Body>
        </div>
			</Modal>}

      </div>
    </>
  )
}

export const pageQuery = graphql`
  query newDevelopementQuery($slug: String!) {
    glstrapi {
      newDevelopments(where:{Slug: $slug}) {
        About_Title
        About_content
        AreaGuide {
          id
          URL
          Select_Areaguide_Location
          Embed_Video_URL
          Title
          Intro_Caption
          Tile_Image {
            publicURL : url
            url
          }
          Embed_Video_URL  
          imagetransforms 
          
        }
        Imagetransforms
        Banner_Content
        New_Development_Modal {
          Modal_Title
          Modal_Content
          ShowModal
          Modal_CTA
          Modal_CTA_URL
          Modal_Bg {
            alternativeText
            url
          }
        }
        Banner_Video_URL
        Banner_Image {
          publicURL : url
           
        }
        Banner_Title
        Brochure {
          publicURL : url
        }
        Call_Number
        Completion_Date
        Calendly_Link 
        Content
        Developer
        Well_Connected
        Developer_Type
        Gallery_List {
          Video_Url
          Image {
            publicURL : url
            url
             
          }
        }
        Latitude
        List {
          Content
          Title
        }
        Area_Slug
        Location
        Longitude
        Meta_Description
        Meta_Title
        Price_Max
        Price_Min
        Sizes
        Slug
        Title
        id
         
      }
    }
       
      strapiGlobalConfig {
          Get_In_Touch_Content
          Get_In_Touch_Embed_Video_Link
          Footer_Office_Address
          Footer_Newsletter_Title
          Instagram_Link
          Linkedin_Link
          Twitter_Link
          Facebook_Link
          Get_In_Touch_Image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 900 ,maxHeight: 600) {
              ...GatsbyImageSharpFluid
              }
            }
          }
          Team_Box_Content
          Team_Box_Video_URL
          Team_Box_Image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 900 ,maxHeight: 600) {
              ...GatsbyImageSharpFluid
              }
            }
          }
          Clients {
            Client
            Client_Image {
              publicURL
            }
            Client_Url
          }
          Footer_Links {
            label
            Menu_item {
              URL
              Secondary_URL
            }
          }
        }
    }
`