import React, { useState } from "react";
import ReactMarkdown from "react-markdown/with-html";
import "./NewModal.scss";
const NewModal = (props) =>{
    //console.log("props",props)
  return(
    <div className="new-modal-section">
      
        <div className="new-modal-content">
          <ReactMarkdown source={props?.data?.Modal_Content} escapeHtml={false} />
        </div>
          <div className="btn-wrap">
            <a href={props?.data?.Modal_CTA_URL} className="btn btn-success">{props?.data?.Modal_CTA}</a>
          </div>
       </div>
   
  );
}
export default NewModal