import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import { Close, VideoPlayButton } from '../../components/icon';
import ReactPlayer from "react-player";
import Modal from 'react-bootstrap/Modal'
import {
	isIOS,
	isMobileOnly,
	isBrowser,
	isMobile
} from "react-device-detect";

function Gallery(props) {
	const [modalshow, setModalShow] = useState(false);
	const inputRef = React.useRef(null)
	const [play, setPlayvideo] = useState(true);
	const [mute, setMute] = useState(true);

	const [controls, setVideoControls] = useState(false);
	const [videoUrl, setVideoUrl] = useState(false);

	const vidRef = useRef(null);
	const pausevideo = (ref) => {
		setPlayvideo(false)
		setVideoControls(false)

	}

	const playvideo = (ref) => {

		setPlayvideo(true)
		setVideoControls(false)
	}

	const settings = {
		dots: false,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: false,
		centerMode: true,
		centerPadding: "20px",
		responsive: [
			{
			  breakpoint: 1800,
			  settings: {
				slidesToShow: (props?.Gallery_List?.length > 1) ? 2 : 1,
				slidesToScroll: 1,
				centerPadding: "100px",
			  },
			},
			{
			  breakpoint: 1293,
			  settings: {
				slidesToShow: (props?.Gallery_List?.length > 1) ? 2 : 1,
				slidesToScroll: 1,
				centerPadding: "70px",
			  },
			},
			{
			  breakpoint: 991,
			  settings: {
				dots: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				centerPadding: "0px",
				autoplay: true,
			  },
			}
		]
	};

	return (
		<div className="news-slider" id="gallery">
			<Slider {...settings}>
				{props.Gallery_List && props.Gallery_List.map((item, index) =>(
						<div key={index} className="news-block text-center">
							<figure className="news-img">
								<img src={item.Image?.publicURL} alt="banner-img" />

								{item.Video_Url &&
									<button className="btn-play"
										onClick={() => { setModalShow(true); playvideo(); setVideoUrl(item.Video_Url) }}>
										<VideoPlayButton />
									</button>
								}
							</figure>
						</div>
				))}
			</Slider >
			{setModalShow && videoUrl &&
				<Modal dialogClassName="modal-full-video" show={modalshow} fullscreen={true} onHide={() => setModalShow(false)}>
					<div className="full-modal-close">
						<a href="javascript:;" onClick={() => { setModalShow(false); }}><Close /></a>
					</div>
					<ReactPlayer autoplay ref={vidRef} url={videoUrl} controls={isIOS && isMobileOnly ? true : false} muted={false} playsinline={true} playing={true} onEnded={() => { setPlayvideo(false) }} width='100%' height='100%' />
				</Modal>
			}
		</div>


	)

}

export default Gallery;