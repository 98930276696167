import { Container } from 'react-bootstrap';
import React, { useState } from "react";
import ReactMarkdown from "react-markdown/with-html";
import $ from "jquery"
import "animate.css/animate.css";
import Modal from 'react-bootstrap/Modal'
import Brochure from "../../components/forms/download-brochure"
import SalesForceForm from "../../components/forms/salesforce-form"
const Description = (props) => {
	//console.log("props",props)
	function gotohash(event) {
		var thishash = '#' + event;
		$('html, body').animate({
			scrollTop: $(thishash).offset().top - 150
		}, 2000);
	}
	const [show, setShow] = useState(false);

	return (
		<section className="section-description">
			<nav className="secondary-nav">
				<Container>
					<ul className="d-flex">
						<li>
							<a onClick={() => gotohash('description-section')} href="javscript:;">description</a>
						</li>

						<li>
							<a onClick={() => gotohash('gallery')} href="javscript:;">gallery</a>
						</li>
						{props.Brochure &&
							<li>
								<a onClick={() => setShow(true)} href="javscript:;">Brochure</a>
							</li>
						}
						<li>
							<a onClick={() => gotohash('location')} href="javscript:;">map</a>
						</li>
						<li>
							<a onClick={() => gotohash('local-life')} href="javscript:;">local area</a>
						</li>
					</ul>
				</Container>
			</nav>


			<div className='about-text' id="description-section">

				<Container className="rowContent" >
					<div className="head-sec text-center  textc"  >
						<span className="sub-title">Overview</span>
						<ReactMarkdown source={props?.Content} escapeHtml={false} />
					</div>			
					<div className='features'>
						<div className='items borderbt'>
							{props?.Location && props?.Location != "" && (<p><h4>Location:</h4> <span>{props?.Location}</span></p>)}
							{props?.Completion_Date && props?.Completion_Date !="" && (<p><h4>Completion Date:</h4> <span>{props?.Completion_Date}</span></p>)}
						</div>
						<div className='items borderbt'>
							{props?.Developer && (<p>{props.Banner_Title === "Manchester Gardens, Stretford" ? <h4>Rental Yields:</h4> : props.Banner_Title === "New Development in Canary Wharf" ? <h4>Tenure:</h4> : <h4>Developer:</h4>} <span>{props?.Developer}</span></p>)}
							{props?.Well_Connected && props?.Well_Connected != "" && (<p><h4>Well Connected:</h4> <span>{props?.Well_Connected}</span></p>)}
						</div>
						<div className='items'>
							{props?.Developer_Type && props?.Developer_Type != "" && (<p><h4>Development Type:</h4> <span>{props?.Developer_Type}</span></p>)}
							{props?.Sizes && props?.Sizes != "" && (<p>{props.Banner_Title === "Urban Village, Preston" || props.Banner_Title === "Lofthaus, Preston" ? <h4>Rental Yield: </h4> : <h4>Sizes From: </h4>}<span>{props?.Sizes}</span></p>)}
						</div>
					</div>
				</Container>

			</div>
			<Modal
				show={show}
				centered={true}
				onHide={() => setShow(false)}
				dialogClassName="modal-90w modal-form"
				aria-labelledby="example-custom-modal-styling-title"
			>
				<Modal.Header closeButton>
					<Modal.Title id="example-custom-modal-styling-title">
					Download A Brochure
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{/* <Brochure brochure={props.Brochure?.publicURL} subject={props?.Title} /> */}
					<SalesForceForm className="full-width" propertyName={props.Title} retURL={process.env.GATSBY_SITE_URL+"/thank-you-for-development-brochure-download"}/> 
				</Modal.Body>
			</Modal>
		</section>

	)
}

export default Description
