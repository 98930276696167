import React, { useState, useEffect } from "react";
import { Container, Col, Row, Form } from 'react-bootstrap'; 
import RegisterForm from "../../components/forms/register-interest" 
import SalesForceForm from "../../components/forms/salesforce-form"

function Register(props) {



	return (
		<>
			<section className="section-contact" id="register"> 
				<Container>
					<Row >
						<Col className="textContact animate__animated "> 
							<div className="register-form">
								<Form >
									<Container>
										<Row className="justify-content-center">
											<Col lg={8}>


												<h1 className="register-form-h1">
													Register your interest
												</h1>
												 
												{/* <RegisterForm subject={props.Title} office={props?.Area_Slug}/> */}
												<SalesForceForm className="full-width register-form" propertyName={props.Title} retURL={process.env.GATSBY_SITE_URL+"/thank-you-for-register-interest"} /> 

											</Col>
										</Row>
									</Container>
								</Form>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</>
	)
}
export default Register

