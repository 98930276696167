import { Container, Row, Card, Button, Accordion } from 'react-bootstrap';
import React, { useState } from "react";
import ReactMarkdown from "react-markdown/with-html";
import $ from "jquery"
import "animate.css/animate.css";
// import ScrollAnimation from 'react-animate-on-scroll';

const About = (props) => {

	const [activekey, setActiveKey] = useState(0)

	return (
		<section className="section-about">
			<div className='about-text' id="description-section">
				{/* <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} > */}
					<Container className="rowContent" >
						<div className="head-sec text-center  textc"  >
							<span className="sub-title">About This development</span>
							<p className="title">{props.About_Title}</p>
							<ReactMarkdown source={props.About_content} escapeHtml={false} />

						</div>
						<div className='about-list'>
							<Accordion>
								{props?.List.map((item, k) => {
									return (
										<Card className="list" key={k}>
											<Card.Header >
												<Accordion.Toggle eventKey={k + 1} onClick={() => {
													if (activekey != k + 1) {
														setActiveKey(k + 1)
													} else {
														setActiveKey(0)
													}

												}}>
												
													<span>{item.Title}</span> <span className='accordion-icon'>{activekey == (k + 1) ? '-' : '+'}</span>
												</Accordion.Toggle>
											</Card.Header>
											<Accordion.Collapse eventKey={k + 1}>
												<ReactMarkdown source={item.Content} escapeHtml={false} />
											</Accordion.Collapse>
										</Card>
									)
								})}

							</Accordion>
						</div>

					</Container>
				{/* </ScrollAnimation> */}
			</div>
		</section>

	)
}

export default About
