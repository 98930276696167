import React from 'react';
import { Link } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import { VideoPlayButton, Logo } from '../icon';
// import ScrollAnimation from 'react-animate-on-scroll';


function Areaget({ areaguide }) {

	const [showVideo, setShowVideo] = React.useState(false);
	const [isPlay, setPlay] = React.useState(false);

	let processedImages = JSON.stringify({});
	if (areaguide?.imagetransforms?.Tile_Image_Transforms) {
		processedImages = areaguide.imagetransforms.Tile_Image_Transforms;
	}
	if (areaguide.Embed_Video_URL) {
		var getVidId = getVideoId(areaguide.Embed_Video_URL);
	}

	return (
		<>
			{areaguide &&
				<section className="section-explore areaproperty">
					<Container className="market animate__animated ">
						<Row className="align-items-lg-center flex-lg-row-reverse">
							<Col lg="6" className="col-xl-7 imgsec">
								{/* <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} > */}

									<div className="vedio-card property_areaguide">

										<ImageTransform imagesources={areaguide.Tile_Image.url} renderer="srcSet" imagename="areaguides.Tile_Image.commontile" attr={{ alt: 'AreaGuide ' + areaguide.Title + ' - Orlando Reid' }}
											imagetransformresult={processedImages} id={areaguide.id} testparam={true} />
										{areaguide.Embed_Video_URL != null && areaguide.Embed_Video_URL != '' &&

											<button className="btn-play"
												onClick={e => { setPlay(true); setShowVideo(true) }}
											>
												<VideoPlayButton />
											</button>

										}
										{
											showVideo &&

											<YouTube
												video={getVidId.id}
												autoplay
											// onPlaying={trackerVideo(areaguide.Title)} 
											/>

										}
									</div>
								{/* </ScrollAnimation> */}
							</Col>

							<Col lg="6" className="col-xl-5 textcont ">
								{/* <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} > */}
									<div className="property-report">
										<span className="sub-title">{areaguide.Title} area guide</span>
										<h2>{areaguide.Intro_Caption}</h2>
										<Link className="" to={`/about-us/areas-we-cover/${areaguide.Select_Areaguide_Location.toLowerCase()}-area-guides/${areaguide.URL}/`} >+ Read more</Link>
									</div>
								{/* </ScrollAnimation> */}
							</Col>

						</Row>
					</Container>
				</section>
			}
		</>
	)


}

export default Areaget