import { Container, Button, Form } from 'react-bootstrap';
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown/with-html";
import CallIcon from "../../images/call-icon.svg"
import WhatsappIcon from "../../images/whatsapp-icon.svg"
import $ from "jquery"
import { useLocation } from "@reach/router"
import { Close, VideoPlayButton, Search, VideoPause, Mute, UnMute } from '../icon';
import ReactPlayer from "react-player";
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import Modal from 'react-bootstrap/Modal'
import {
  isIOS,
  isMobileOnly,
  isBrowser,
  isMobile
} from "react-device-detect";



const Banner = (props) => {

  const [modalshow, setModalShow] = useState(false);
  const inputRef = React.useRef(null)
  const [play, setPlayvideo] = useState(true);
  const [mute, setMute] = useState(true);

  const [controls, setVideoControls] = useState(false);

  const vidRef = useRef(null);
  const pausevideo = (ref) => {
    setPlayvideo(false)
    setVideoControls(false)

  }

  const playvideo = (ref) => {

    setPlayvideo(true)
    setVideoControls(false)
  }

  
  function gotohash(event) {
    var thishash = '#' + event;
    $('html, body').animate({
      scrollTop: $(thishash).offset().top - 150
    }, 2000);
  }
  const location = useLocation();
  const pagelink = location.href;
  const siteUrl= process.env.GATSBY_SITE_URL
  const Domacpage = siteUrl + '/new-development-in-manchester/damac-tower-london/'
  
  var whatsappPhoneNumber = "+447450209485";
  var whatsappPhoneNumber2 = '+447833457485';
  var msg = `Hi there, I am enquiring from this page ${pagelink} on the website.`;
  var msg2 = `Hi Jack, I am interested in discussing about properties with Orlando Reid. I am inquiring from this page ${Domacpage} on the website`;
  let whatsappurl = `https://wa.me/${whatsappPhoneNumber}?text=${msg}`;

  if(props.id == '65311b01c0721379d5a44a26'){
      whatsappurl = `https://wa.me/${whatsappPhoneNumber2}?text=${msg2}`;
  }
  //console.log("bannerprops", props);

  return (
    <section className="banner"> 
      {props.Banner_Image != null &&
        <div className="banner-img">
          <picture>
            <source media="(min-width:1200px)" srcset={props.Banner_Image?.publicURL} />
            <source media="(min-width:768px)" srcset={props.Banner_Image?.publicURL} />
            <img src={props.Banner_Image?.publicURL} alt="banner-img" />
          </picture>
        </div>
      }
      <div className="banner-content text-center">
        <Container>
          {props.Banner_Title != null &&
            <h1>{props.Banner_Title}</h1>
          }
          {
            props.Banner_Title === "Lofthaus, Preston" || props.Banner_Title === "Urban Village, Preston" ? 
              <p className='banner-price-value'> Starting Price  {'£' + parseInt(props.Price_Min).toLocaleString()}</p>
            : props.Banner_Title === "Novella, Salford" ? "" :  <p className='banner-price-value'>{props.Price_Min && ('£' + parseInt(props.Price_Min).toLocaleString())} {props.Price_Max && "-"} {props.Price_Max && ('£' + parseInt(props.Price_Max).toLocaleString())}</p>
          }
          <ReactMarkdown source={props.Banner_Content} escapeHtml={false} />
          {props?.Banner_Video_URL && props.Banner_Video_URL.trim() !== "" &&
            <a href="javascript:;" onClick={() => { setModalShow(true); playvideo(); }}>  <VideoPlayButton /></a>
          }

          <div className="btn-wrap">
            <a onClick={() => gotohash("register")} className="btn btn-success">Register Interest</a>
            <a href={`tel:${props.Call_Number}`} className="btn btn-white">Call {props.Call_Number}</a>
          </div>
        </Container>
      </div>
      <div className="sticky-btn">
        {props.Enquire_Now && <a href={'/investment-enquiry/'} className="btn btn-success book-a-call"><span>Enquire Now</span></a>}
        {/* <a href={`tel:${props.Call_Number}`} ><img src={CallIcon} /></a> */}
        {/* <a href={whatsappurl} target="_blank" ><img src={WhatsappIcon} /></a> */}
      </div>
      {setModalShow && props?.Banner_Video_URL && props.Banner_Video_URL.trim() !== "" &&
        <Modal dialogClassName="modal-full-video" show={modalshow} fullscreen={true} onHide={() => setModalShow(false)}>
          <div className="full-modal-close">
            <a href="javascript:;" onClick={() => { setModalShow(false); }}><Close /></a>
          </div>
          <ReactPlayer autoplay ref={vidRef} url={props.Banner_Video_URL} controls={isIOS && isMobileOnly ? true : false} muted={false} playsinline={true} playing={true} onEnded={() => { setPlayvideo(false) }} width='100%' height='100%' />
        </Modal>
      }
    </section>

  )
}

Banner.propTypes = {
  siteTitle: PropTypes.string,
}

Banner.defaultProps = {
  siteTitle: ``,
}

export default Banner